import { ChangeEvent, Dispatch, SetStateAction, createRef, useEffect, useState } from 'react'
import { FORM_THEMES, SURVEY_THEMES } from '../Themes/themes_constants'
import { isValidHexColor } from 'utils/common/isValidHexColor'
import { useDispatch, useSelector } from 'react-redux'
import { addSurvey, editSurvey } from 'store/surveysSlice/surveysDispatchers'
import { AppDispatch, RootState } from 'store'
import { retrieveLocations } from 'store/accountsSlice/locations/dispatchers.locations'
import _ from 'lodash'
import { setSelectedLocations } from 'store/accountsSlice/locations'
import { FORM } from 'pages/Settings/ManageForms/ManageForms'
import { SURVEY } from 'pages/Settings/ManageSurveys/ManageSurveys'
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { locationChoicesType, SurveyAddCreatedResponse } from 'store/surveysSlice/surveyTypes'
import { getLocationLockedQuestion } from '../SurveyCreator/defaultQuestions'

export interface EditableSurveyDetails {
  surveyName: string
  customPrivacyPolicyFooter?: boolean
  customPrivacyPolicyText?: string
  customPrivacyPolicyLink?: string
  uniqueSubmission?: boolean
  savePartiallyCompleted?: boolean
  locations?: locationChoicesType
  isAllLocations?: boolean
  requiredEntity?: string
}
export interface SurveyDetailsTypes {
    surveyName: string
    surveyBrandColor?: string
    locationTracking: string
    customPrivacyPolicyFooter: boolean
    customPrivacyPolicyText?: string
    uniqueSubmission?: boolean
    customPrivacyPolicyLink?: string
    savePartiallyCompleted: boolean
    askContactDetails?: boolean
    locations: locationChoicesType
    selectedTheme?: string
    isAllLocations: boolean
    requiredEntity: string
  }

const findLocationQuestion = (definition: { pages: Array<{ name: string, elements: Array<{name: string, choices?: object[]}>}>}) => {
  if (!definition.pages) {
    return null
  }
  const pageIndex = definition.pages.findIndex(page => {
    if (page.elements) {
      return page.elements.some(element => element.name === 'location-locked-question')
    }
    return false
  })

  if (pageIndex > -1) {
    const elementIndex = definition.pages[pageIndex].elements.findIndex((element: { name: string }) => element.name === 'location-locked-question')
    if (elementIndex !== -1) {
      return [pageIndex, elementIndex]
    }
  }
}

const removeLocationQuestion = (json: string) => {
  const definition = JSON.parse(json)

  const locationQuestionIndexes = findLocationQuestion(definition)

  if (locationQuestionIndexes) {
    const [pageIndex, elementIndex] = locationQuestionIndexes
    definition.pages[pageIndex].elements.splice(elementIndex, 1)
  }
  return definition
}
const replaceChoicesForLocationLockedQuestion = (json: string, locations: locationChoicesType) => {
  const definition = JSON.parse(json)

  const locationQuestionIndexes = findLocationQuestion(definition)

  if (locationQuestionIndexes) {
    const [pageIndex, elementIndex] = locationQuestionIndexes
    definition.pages[pageIndex].elements[elementIndex].choices = locations
  } else {
    if (!definition.pages[0].elements) {
      definition.pages[0].elements = []
    }
    definition.pages[0].elements.push(getLocationLockedQuestion(locations))
  }
  return definition
}

export const useEditSurveyDetails = () => {
  const { data } = useSelector((state: RootState) => state.surveys.surveySetup)

  const [disabledLocationOptions, setDisabledLocationOptions] = useState<string[]>([])
  const [editedFields, setEditedFields] = useState<string[]>([])
  const [surveyDetails, setSurveyDetails] = useState<SurveyDetailsTypes>({
    surveyName: data.surveyName,
    locationTracking: data.locationTracking,
    customPrivacyPolicyFooter: data.attributes?.privacyPolicy?.text || data.attributes?.privacyPolicy?.link,
    customPrivacyPolicyText: data.attributes?.privacyPolicy?.text,
    customPrivacyPolicyLink: data.attributes?.privacyPolicy?.link,
    uniqueSubmission: data.isUniqueSubmission,
    savePartiallyCompleted: data.isAllowIncomplete,
    locations: [],
    isAllLocations: data.isAllLocations,
    requiredEntity: data.requiredEntity || 'None'
  })

  const handleChange = (key: keyof SurveyDetailsTypes, value: string | boolean) => {
    setSurveyDetails(prevState => ({
      ...prevState,
      [key]: value
    }))
    setEditedFields(prev => {
      const edited = new Set([...prev, key])
      return Array.from(edited)
    })
    if (key === 'isAllLocations' && value === false) {
      setSurveyDetails(prevState => ({
        ...prevState,
        locations: []
      }))
    }
  }

  useEffect(() => {
    if (data.locationTracking === 'disabled') {
      setDisabledLocationOptions(['code'])
    } else if (data.locationTracking === 'question') {
      setDisabledLocationOptions(['code'])
      const { pages } = JSON.parse(data.json)
      if (!surveyDetails.isAllLocations) {
        const locationQuestion = pages
          .flatMap((page: { elements: any[] }) => page.elements)
          .find((element: { name: string }) => element.name === 'location-locked-question')
        if (locationQuestion) {
          setSurveyDetails(prevState => ({
            ...prevState,
            locations: locationQuestion.choices
          }))
        }
      }
    } else if (data.locationTracking === 'code') {
      setDisabledLocationOptions(['disabled', 'question', 'code'])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { surveyDetails, setSurveyDetails, handleChange, disabledLocationOptions, editedFields, setEditedFields }
}

export const useSurveyDetailsSetup = () => {
  const { data: locations } = useSelector((state: RootState) => state.locations.locations)
  const { id: surveyId } = useSelector((state: RootState) => state.surveys.surveySetup)
  const dispatch: AppDispatch = useDispatch()
  const [surveyDetails, setSurveyDetails] = useState<SurveyDetailsTypes>({
    surveyName: '',
    surveyBrandColor: '',
    locationTracking: 'disabled',
    customPrivacyPolicyFooter: false,
    uniqueSubmission: false,
    customPrivacyPolicyText: undefined,
    customPrivacyPolicyLink: undefined,
    savePartiallyCompleted: false,
    askContactDetails: false,
    locations: [],
    isAllLocations: false,
    selectedTheme: 'theme1',
    requiredEntity: 'None'
  })

  const handleChange = (key: keyof SurveyDetailsTypes, value: string | boolean) => {
    if (!locations) {
      dispatch(retrieveLocations({ first: 30 }))
    }
    setSurveyDetails(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const isEditingDetails = typeof surveyId === 'string'

  const disabledSaveButton = (surveyDetails.locationTracking !== 'disabled' && surveyDetails.locations.length === 0 && !surveyDetails.isAllLocations) || !surveyDetails.surveyName || (surveyDetails.customPrivacyPolicyFooter && (!surveyDetails.customPrivacyPolicyText || !surveyDetails.customPrivacyPolicyLink)) || (surveyDetails.surveyBrandColor !== '' && !isValidHexColor(surveyDetails.surveyBrandColor!))

  return { surveyDetails, handleChange, disabledSaveButton, setSurveyDetails, isEditingDetails }
}

export const useSaveDetails = ({ surveyDetails, surveyType } : { surveyDetails: SurveyDetailsTypes, surveyType: string }) => {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()

  const { loading: isSavingDetails } = useSelector((state: RootState) => state.surveys.surveySetup)

  const handleSaveDetails = async () => {
    dispatch(setSelectedLocations({}))
    const themes: any[] = surveyType === SURVEY ? SURVEY_THEMES : FORM_THEMES
    const surveyTheme = themes.find((theme) => theme.id === surveyDetails.selectedTheme)
    if (surveyTheme && surveyDetails.surveyBrandColor) {
      surveyTheme.theme_json.cssVariables['--sjs-primary-backcolor'] = surveyDetails.surveyBrandColor
    }

    const hasCustomPrivacyPolicy = surveyDetails.customPrivacyPolicyFooter

    const attributes = {
      privacyPolicy: {
        text: surveyDetails.customPrivacyPolicyText,
        link: surveyDetails.customPrivacyPolicyLink
      }
    }

    const surveyInput = {
      surveyName: surveyDetails.surveyName,
      theme: JSON.stringify(surveyTheme),
      isAllowIncomplete: surveyDetails.savePartiallyCompleted,
      askContactDetails: surveyDetails.askContactDetails,
      isAllLocations: surveyDetails.isAllLocations,
      uniqueSubmission: surveyDetails.uniqueSubmission,
      attributes: hasCustomPrivacyPolicy ? JSON.stringify(attributes) : undefined,
      locationTracking: surveyDetails.locationTracking,
      locations: surveyDetails.locations,
      isForm: surveyType === FORM && true,
      requiredEntity: surveyDetails.requiredEntity
    }
    await dispatch(addSurvey(surveyInput))
      .then((res) => {
        const payload = res.payload as SurveyAddCreatedResponse
        const { id } = payload.surveyAdd.created
        if (id) {
          navigate(id, { replace: true })
        }
      })
  }

  return { handleSaveDetails, isSavingDetails }
}

export const useSaveEditedDetails = ({ surveyDetails, editedFields, allLocations, toggleIsEditingDetails } : { surveyDetails: SurveyDetailsTypes, editedFields: string[], allLocations?: locationChoicesType, toggleIsEditingDetails: () => void }) => {
  const { data } = useSelector((state: RootState) => state.surveys.surveySetup)
  const dispatch: AppDispatch = useDispatch()

  const { loading: isSavingDetails } = useSelector((state: RootState) => state.surveys.surveySetup)
  const handleSaveDetails = async () => {
    const hasCustomPrivacyPolicy = surveyDetails.customPrivacyPolicyFooter

    const surveyInput: any = {
      surveyName: surveyDetails.surveyName,
      isAllowIncomplete: surveyDetails.savePartiallyCompleted,
      isAllLocations: surveyDetails.isAllLocations,
      isUniqueSubmission: surveyDetails.uniqueSubmission,
      locationTracking: surveyDetails.locationTracking,
      requiredEntity: surveyDetails.requiredEntity
    }

    // Handle policy footer changes
    const policyFooterWasRemoved = editedFields.includes('customPrivacyPolicyFooter') && !hasCustomPrivacyPolicy
    let attributes = null
    if (policyFooterWasRemoved) {
      attributes = {
        privacyPolicy: {
          text: '',
          link: ''
        }
      }
    }

    const policyFooterFieldsWereUpdated = editedFields.includes('customPrivacyPolicyText') || editedFields.includes('customPrivacyPolicyLink')
    if (policyFooterFieldsWereUpdated) {
      attributes = {
        privacyPolicy: {
          text: surveyDetails.customPrivacyPolicyText,
          link: surveyDetails.customPrivacyPolicyLink
        }
      }
    }

    if (attributes) {
      surveyInput.attributes = JSON.stringify(attributes)
    }

    // See if location question needs update and therefore definition
    let definition = null
    const locationQuestionWasDisabled = editedFields.includes('locationTracking') && surveyDetails.locationTracking === 'disabled'
    // if it doesn´t found the locations question it will add it in replace choices method below
    const editedLocations = editedFields.includes('locations') && surveyDetails.locationTracking === 'question' && !surveyDetails.isAllLocations
    const changedQuestionToHaveAllLocations = editedFields.includes('isAllLocations') && surveyDetails.isAllLocations

    if (locationQuestionWasDisabled) {
      definition = removeLocationQuestion(data.json)
    } else if (editedLocations) {
      definition = replaceChoicesForLocationLockedQuestion(data.json, surveyDetails.locations)
    } else if (changedQuestionToHaveAllLocations) {
      definition = replaceChoicesForLocationLockedQuestion(data.json, allLocations!)
    }

    if (definition) {
      surveyInput.definition = JSON.stringify(definition)
    }

    dispatch(editSurvey(surveyInput))
    toggleIsEditingDetails()
  }

  return { handleSaveDetails, isSavingDetails }
}

export const useGetLocations = (surveyDetails: SurveyDetailsTypes, setSurveyDetails: Dispatch<SetStateAction<SurveyDetailsTypes>>, getAllLocations: LazyQueryExecFunction<any, OperationVariables>, isEditing: boolean = false) => {
  const { data: locations, loading: loadingLocations } = useSelector((state: RootState) => state.locations.locations)
  const [searchLocation, setSearchLocation] = useState('')
  const [values, setValues] = useState<string[]>([])
  const dispatch: AppDispatch = useDispatch()

  const input = createRef()
  const onAdd = (optionValue: string) => {
    const updatedValue = [...values, optionValue]
    setValues(updatedValue)
    const location = locations?.locations.edges.find((location) => location.node.name === optionValue)

    setSurveyDetails(prevState => {
      return {
        ...prevState,
        locations: [...prevState.locations, { text: optionValue, value: location?.node.uuid || '' }]
      }
    }
    )
  }

  const onRemove = (optionValue: string) => {
    const updatedValue = values.filter((val) => val !== optionValue)
    setValues(updatedValue)
    setSurveyDetails(prevState => {
      return {
        ...prevState,
        locations: prevState.locations.filter((location) => location.text !== optionValue)
      }
    }
    )
  }

  const clearSelection = () => {
    setValues([])
    setSurveyDetails(prevState => ({
      ...prevState,
      locations: []
    }))
  }
  const handleSearch = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearchLocation(e.target.value)
  }, 1000)

  useEffect(() => {
    if (surveyDetails.locationTracking !== 'disabled') {
      dispatch(retrieveLocations({ searchParam: searchLocation, first: 30 }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLocation])

  useEffect(() => {
    if (surveyDetails.isAllLocations) {
      setValues([])
      getAllLocations()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetails.isAllLocations])

  useEffect(() => {
    if (isEditing && values.length === 0 && values.length !== surveyDetails.locations.length) {
      setValues(surveyDetails.locations.map(location => location.text))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetails.locations])

  return { onAdd, onRemove, clearSelection, locations, loadingLocations, handleSearch, input, values, setValues }
}
