import locationsState from 'store/accountsSlice/locations/types.locations'

export const getLocationLockedQuestion = (selectedLocations: locationsState['locationsSelected']) => ({
  type: 'dropdown',
  name: 'location-locked-question',
  isRequired: true,
  choices: selectedLocations,
  title: 'Location'
})

export const getAskContactDetailsQuestions = () => ([
  {
    type: 'text',
    name: 'contact-first-name',
    title: 'First Name',
    isRequired: true
  },
  {
    type: 'text',
    name: 'contact-last-name',
    title: 'Last Name'
  },
  {
    type: 'text',
    name: 'contact-email',
    title: 'Email',
    isRequired: true,
    inputType: 'email'
  },
  {
    type: 'text',
    name: 'contact-phone',
    title: 'Phone'
  }
])
