import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { PostActionRulesProps, Question, Trigger } from './AddPostSurveyActions.types'
import { getAnswerFormat, jsonLogicOperators } from './jsonLogic'
import { POST_ACTIONS, TRIGGER_TYPES } from './constants'
import { CHANNEL_TYPES } from 'utils/constants'
import { retrieveTagGroups } from 'store/tags/tags/dispatchers.tags'
import { useMutation } from '@apollo/client'
import { POST_SURVEY_ACTION_ADD } from 'requests/Surveys'
import { Choice } from 'types/common'
const { EQUALS } = jsonLogicOperators

export type Tag = { entityType?: string, tagField: { uuid: string, id: string }, value: string }

const { AUTO_REPLY } = POST_ACTIONS

const transformTagsToAttributes = ({ tags }: { tags: Tag[] }): { tags: { tag_field: string; value: string }[] } => {
  const values = tags.length && tags.map((tag) => ({ tag_field: tag.tagField.uuid, value: tag.value }))
  return { tags: values || [] }
}

export const useAddPostSurveyActions = () => {
  const { localData } = useSelector((store: RootState) => store.surveys.surveyQuestions)
  const [triggerType, setTriggerType] = useState<string | undefined>(undefined)
  const [questions, setQuestions] = useState<Question[]>([])
  const [answerChoices, setAnswerChoices] = useState<any[]>([])
  const [trigger, setTrigger] = useState<Trigger>({ question: { name: undefined, type: undefined }, answer: undefined })

  useEffect(() => {
    if (localData && localData.pages) {
      const extractedQuestions = localData.pages.flatMap((page: any) =>
        page.elements.map((element: any) => ({
          name: element.name,
          title: element.title,
          type: element.type,
          choices: element.choices
            ? convertChoices(element.choices)
            : []
        }))
      )

      setQuestions(extractedQuestions)
    }
  }, [localData])

  useEffect(() => {
    if (trigger.question.name) {
      if (trigger.question.type === 'boolean') {
        setAnswerChoices([
          { text: 'Yes', value: 'true' },
          { text: 'No', value: 'false' }
        ])
        return
      }
      if (trigger.question.type && trigger.question.type !== 'boolean') {
        const selectedQuestionData = questions.find(question => question.name === trigger.question.name)
        if (selectedQuestionData && selectedQuestionData.choices) {
          setAnswerChoices(selectedQuestionData.choices)
          return
        }
        setAnswerChoices([])
      }
    }
  }, [trigger.question, questions])

  const handleSelectedQuestionChange = (questionName: string) => {
    const selectedQuestion = questions.find(question => question.name === questionName)
    if (selectedQuestion) {
      setTrigger({ question: { name: selectedQuestion.name, type: selectedQuestion.type }, answer: undefined })
    }
  }

  const handleSelectedAnswerChange = (answer: string | number) => {
    setTrigger({ ...trigger, answer })
  }

  const handleSelectedTriggerTypeChange = (triggerType: string) => {
    setTriggerType(triggerType)
  }

  return { questions, trigger, handleSelectedQuestionChange, handleSelectedAnswerChange, answerChoices, triggerType, handleSelectedTriggerTypeChange }
}

interface Attributes {
  [key: string]: any
}

export const usePostActionRules = ({ trigger, id, toggleModalOpen, triggerType, refetch }: PostActionRulesProps) => {
  const [selectedAction, setSelectedAction] = useState('')
  const [attributes, setAttributes] = useState<Attributes>({})
  const [tags, setTags] = useState<Tag[]>([])
  const formattedAnswer = getAnswerFormat(trigger.answer)
  const jsonLogicEqualsRule = `{"if" : [{"${EQUALS}": [{"var":"${trigger.question.name}"}, ${formattedAnswer}]}]}`
  const [addAction, { data, loading, error }] = useMutation(POST_SURVEY_ACTION_ADD)
  const rule = JSON.parse(triggerType === TRIGGER_TYPES.QUESTION ? jsonLogicEqualsRule : `{"if" : [${true}]}`)

  const addPostSurveyAction = async () => {
    await addAction({ variables: { input: { survey: id, rule, postAction: selectedAction, attributes: tags.length > 0 ? transformTagsToAttributes({ tags }) : attributes } } })
    refetch()
  }

  const changeAttributes = (attribute: string, value: any) => {
    setAttributes(prev => ({ ...prev, [attribute]: value }))
  }

  useEffect(() => {
    if (!loading && data) {
      toggleModalOpen()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  useEffect(() => {
    if (selectedAction === AUTO_REPLY) {
      setAttributes({ action_type: CHANNEL_TYPES.EMAIL })
    } else {
      setAttributes({})
    }
  }, [selectedAction])

  return { selectedAction, setSelectedAction, addPostSurveyAction, data, loading, error, changeAttributes, attributes, setTags, tags }
}

export const useHandleInteractionModal = () => {
  const [showModalOpened, setShowModalOpened] = useState(false)
  const dispatch = useDispatch()

  const toggleTaggingModalOpen = () => {
    setShowModalOpened((prev) => !prev)
    if (!showModalOpened) {
      dispatch(retrieveTagGroups({}) as any)
    }
  }

  return { toggleTaggingModalOpen, showModalOpened }
}

export const useHandleAutoResponseModal = () => {
  const [isAutoRespondModalOpen, setIsAutoRespondModalOpen] = useState(false)

  const toggleAutoResponseModalOpen = () => {
    setIsAutoRespondModalOpen((prev) => !prev)
  }

  return { toggleAutoResponseModalOpen, isAutoRespondModalOpen }
}

const convertChoices = (choices: (Choice | string)[]): Choice[] => {
  return choices.map(choice => {
    if (typeof choice === 'string') {
      return { value: choice, text: choice }
    }
    return {
      value: choice.value,
      text: choice.text
    }
  })
}
