import { createAsyncThunk } from '@reduxjs/toolkit'
import { apollo, request } from 'api'
import { ACCOUNTS_LIST, FEATURE_MAINTENANCE, TOGGLE_FEATURE_MAINTENANCE } from 'requests/Accounts'
import { RootState } from '..'
import { AccountsListResponse, FeatureMaintenancePayload } from './accountsTypes'
import { ME } from 'requests/Login'
import { logout, notifyError } from 'store/loginSlice'
import { accountSessionTrackingEvent } from 'utils/dataTracking/accountsTracking'
import { userSessionTrackingEvent } from 'utils/dataTracking/usersTracking'
import { TOGGLE_NOTIFICATIONS } from 'requests/Accounts/locations'
import { ACCOUNT_SET_FEATURES } from 'requests/Settings'
import { Feature } from 'types/account'
import { retrieveTagGroups } from 'store/tags/tags/dispatchers.tags'

export const bootstrap = createAsyncThunk(
  'accounts/bootstrap',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState

    const myUserData = await apollo.query({ query: ME })
    if (!myUserData.data.me.isEmployee) {
      thunkApi.dispatch(logout())
      setTimeout(() => thunkApi.dispatch(notifyError('You do not have permission to access the Reply Pro Hub')), 500)
    }

    userSessionTrackingEvent(myUserData.data.me.id)

    await thunkApi.dispatch(retrieveAccounts())
    if (state.accounts.accountId) {
      accountSessionTrackingEvent(state.accounts.accountId)
      thunkApi.dispatch(setAccount(state.accounts.accountId))
    } else {
      // thunkApi.dispatch(retrieveInteractionList({ refetch: true }))
    }
    await thunkApi.dispatch(retrieveFeatureMaintenance())
    thunkApi.dispatch(retrieveTagGroups({}))
    /*
      thunkApi.dispatch(retrieveSavedViews(true))
      thunkApi.dispatch(setSavedViewFilterData())
      thunkApi.dispatch(retrieveTagGroups()) */
  }
)
type name = string | undefined
export const retrieveAccounts = createAsyncThunk<AccountsListResponse, name>(
  'accounts/retrieveAccounts',
  async (name) => {
    const response = await apollo.query<AccountsListResponse>({ query: ACCOUNTS_LIST, variables: { name } })
    return response.data
  }
)

export const setAccount = createAsyncThunk<string, string>(
  'accounts/setAccount',
  async (accountId: string, thunkApi) => {
    request({
      path: '/me/authorizations',
      method: 'POST',
      authenticated: true,
      requestOptions: {
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          account: accountId,
          authorize_subaccounts: false
        })
      }
    })

    return accountId
  }
)

export const toggleAccountNotifications = createAsyncThunk<string, string>(
  'accounts/toggleAccountNotifications',
  async (accountId: string, _) => {
    await apollo.mutate({
      mutation: TOGGLE_NOTIFICATIONS,
      variables: {
        input: {
          id: accountId
        }
      }
    })

    return accountId
  }
)

export const setAccountFeatures = createAsyncThunk<string, { accountId: string, features: Feature[] }>(
  'accounts/setAccountFeatures',
  async ({ accountId, features }: { accountId: string, features: string[] }, _) => {
    await apollo.mutate({
      mutation: ACCOUNT_SET_FEATURES,
      variables: {
        input: {
          id: accountId,
          features
        }
      }
    })

    return accountId
  }
)

interface SetFeatureMaintenanceProps { feature: string, maintenance: boolean, status?: string, message?: string }

export const setFeatureMaintenance = createAsyncThunk<string, SetFeatureMaintenanceProps>(
  'accounts/setFeatureMaintenance',
  async ({ feature, maintenance, status, message }, thunkApi) => {
    await apollo.mutate({
      mutation: TOGGLE_FEATURE_MAINTENANCE,
      variables: {
        input: {
          feature, maintenance, status, message
        }
      }
    }).then(() => {
      thunkApi.dispatch(retrieveFeatureMaintenance())
    })

    return feature
  }
)
export const retrieveFeatureMaintenance = createAsyncThunk<FeatureMaintenancePayload>(
  'accounts/retrieveFeatureMaintenance',
  async () => {
    const response = await apollo.query({ query: FEATURE_MAINTENANCE })
    const { reporting, inbox, activities } = response.data

    const featureMaintenanceData: FeatureMaintenancePayload = {
      reporting: { isMaintenance: reporting.isMaintenance, status: reporting.status, message: reporting.message },
      inbox: { isMaintenance: inbox.isMaintenance, status: inbox.status, message: inbox.message },
      activities: { isMaintenance: activities.isMaintenance, status: activities.status, message: activities.message }
    }

    return featureMaintenanceData
  }
)
