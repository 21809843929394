import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode } from 'react'

interface ItemWithId {
  id: string | number
}

interface SortableListProps<T extends ItemWithId> {
  items: T[]
  className?: string
  moveItem: (index: number, direction: number) => void;
  renderItem: (item: T, index: number, moveItem: (index: number, direction: number) => void) => ReactNode
}

export function SortableList<T extends ItemWithId> ({ items, moveItem, renderItem, className }: SortableListProps<T>) {
  return (
    <div className={`sortable-list ${className || ''}`}>
      <AnimatePresence>
        {items.map((item, index) => (
          <motion.div
            key={item.id}
            layout
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`sortable-item ${index === 0 ? 'first-element' : ''}`}
          >
            {renderItem(item, index, moveItem)}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}
