import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import ElementDelete from 'components/ElementDelete'
import { removeReportPage } from 'store/settingsSlice/settingsReducers'
import { reportPage, userGroupNode, userNode } from 'store/settingsSlice/settingsTypes'
import EditReport from './EditReport'
import './report.scss'
import { EyeIcon, Clipboard, ExternalLink, SortingArrows, GridPanel } from '@reply-pro/component-library'
import { copyToClipboard } from 'utils/common'
import Tooltip from 'components/Tooltip/Tooltip'

interface ReportsListProps {
  reportName: string
  users: userNode[]
  userGroups: userGroupNode[]
  reportId: string
  isForAnyUser?: boolean
  link?: string
  remoteId?: string
  embedType: reportPage['embedType']
  sectionId: string
  authorized: boolean
  parameters: Record<string, string[]>
  isReorderingReportsInSection: boolean
  moveUp: () => void
  moveDown: () => void
  disableUp: boolean
  disableDown: boolean
}

export default function Report ({
  reportName,
  users,
  userGroups,
  reportId,
  isForAnyUser,
  link,
  remoteId,
  embedType,
  sectionId,
  authorized,
  parameters,
  isReorderingReportsInSection,
  moveDown,
  moveUp,
  disableDown,
  disableUp
}: ReportsListProps) {
  const { reportCollectionDetail } = useSelector((state: RootState) => state.settings)

  const redirectToMetabase = useRef(null)
  const redirectToReplyProWeb = useRef(null)
  const copyReplyProWeb = useRef(null)

  const copyReportRPWebUrlToClipboard = () => {
    copyToClipboard(`${process.env.REACT_APP_WEB}/reporting/collections/collection/${reportCollectionDetail?.id}/page/${reportId}`)
  }

  return (
    <>
      <GridPanel
        customWidths={['60%', '30%', 'fit-content']}
        className='report-grid-panel'
        columnClassNames={['report-info', 'permissions', 'actions']}
        columns={[
          <div key='report-info'>
            <div>{reportName}</div>
          </div>,

          <div className='permissions' key='permissions'>
            {isForAnyUser
              ? 'All Users'
              : users.length > 0 && userGroups.length > 0
                ? 'Mixed'
                : users.length > 0
                  ? `${users.length} ${users.length > 1 ? 'Users' : 'User'}`
                  : `${userGroups.length} ${userGroups.length > 1 ? 'User Groups' : 'User Group'}`}
          </div>,

          <div className='report-actions' key='actions'>
            {isReorderingReportsInSection
              ? (
                <SortingArrows
                  onUpClick={moveUp}
                  onDownClick={moveDown}
                  disabledUp={disableUp}
                  disabledDown={disableDown}
                />
                )
              : (
                <>
                  <EditReport
                    priorReportUsers={users}
                    priorReportUserGroups={userGroups}
                    reportPageId={reportId}
                    priorName={reportName}
                    priorLink={link}
                    priorRemoteId={remoteId}
                    priorEmbedType={embedType}
                    reportSectionId={sectionId}
                    authorized={authorized}
                    parameters={parameters}
                  />
                  <ElementDelete
                    deleteDispatcher={removeReportPage}
                    id={reportId}
                    type='Report Page'
                    name={reportName}
                  />
                  <a ref={redirectToMetabase} href={link} target='_blank' rel='noreferrer'>
                    <ExternalLink className='action-icon' />
                  </a>
                  <div ref={copyReplyProWeb}>
                    <Clipboard onClick={copyReportRPWebUrlToClipboard} className='action-icon' />
                  </div>
                  <a
                    ref={redirectToReplyProWeb}
                    href={`${process.env.REACT_APP_WEB}/reporting/collections/collection/${reportCollectionDetail?.id}/page/${reportId}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <EyeIcon className='action-icon' />
                  </a>
                </>
                )}
          </div>
        ]}
      />
      <div key='report-tooltips'>
        <Tooltip anchor={redirectToMetabase}>Go to Metabase report</Tooltip>
        <Tooltip anchor={redirectToReplyProWeb}>Go to report within Reply Pro Web</Tooltip>
        <Tooltip anchor={copyReplyProWeb}>Copy Reply Pro Web report URL to clipboard</Tooltip>
      </div>
    </>
  )
}
