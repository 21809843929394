import { useDispatch } from 'react-redux'
import { TrashCanComponent } from '@reply-pro/component-library'
import { AppDispatch } from 'store'
import './index.scss'

interface ElementDeleteProps {
  deleteDispatcher: (id: string) => any
  id: string,
  name?: string,
  type?: string
}

export default function ElementDelete ({ deleteDispatcher, id, name, type }: ElementDeleteProps) {
  const dispatch: AppDispatch = useDispatch()
  const softDelete = () => {
    dispatch(deleteDispatcher(id))
  }

  return (
    <>
      <TrashCanComponent
        className='trash-icon'
        confirmation={{
          onConfirm: softDelete,
          warningFirstLine: `You will not be able to access this ${type}`,
          buttonText: 'Remove',
          confirmText: 'REMOVE',
          modalTitle: `Remove ${name}`
        }}
      />
    </>
  )
}
