/* eslint-disable operator-linebreak */
import { RootState } from 'store'
import './collectionDetail.scss'
import { useSelector } from 'react-redux'
import SectionsList from './SectionsList'
import { LoadingSpinner } from '@reply-pro/component-library'

export default function CollectionDetail () {
  const { reportCollectionDetail, reportCollectionDetailLoading } = useSelector((state: RootState) => state.settings)

  if (reportCollectionDetailLoading) {
    return <div className='central-loading-spinner'><LoadingSpinner /></div>
  }
  return (
    <>
      {reportCollectionDetail ?
        <>
          <SectionsList />
        </>
        : <span className='no-items-found'>No collection Selected</span>}
    </>
  )
}
