import { Button, MainModal, TextInput } from '@reply-pro/component-library'
import { useState, ReactNode } from 'react'
import './addNewModal.scss'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { collectionProps } from 'store/settingsSlice/settingsTypes'

interface AddNewModalProps {
  toggleIsAddNewModalOpen: () => void
  isAddNewModalOpen: boolean
  dispatchAddNew: (payload: collectionProps) => any
  title: string
  children?: ReactNode
  inputLabel: string
  type?: string
}

export default function AddNewModal ({ isAddNewModalOpen, toggleIsAddNewModalOpen, dispatchAddNew, children, title, inputLabel, type }: AddNewModalProps) {
  const [label, setLabel] = useState('')
  const dispatch = useDispatch<AppDispatch>()

  const toggleModalOpen = () => {
    toggleIsAddNewModalOpen()
    setLabel('')
  }

  const addNew = () => {
    dispatch(dispatchAddNew({ name: label, type }))
    toggleModalOpen()
  }

  return (
    <div>
      <MainModal
        title={title}
        actions={<Button disabled={!label} onClick={addNew}>Add</Button>}
        showModal={isAddNewModalOpen}
        toggleModalOpen={toggleModalOpen}
        showTopAndBottomLines
        headerClassName='add-new-modal-header'
        bodyClassName='add-new-modal-body'
        className='add-new-modal'
      >
        <div className='add-new-modal-content'>
          <TextInput
            className='add-new-modal-input'
            label={inputLabel}
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
          {children}
        </div>
      </MainModal>
    </div>
  )
}
