import { useState } from 'react'

export function useReorder<T> (initialItems: T[]) {
  const [items, setItems] = useState(initialItems)

  function moveItem (index: number, direction: number) {
    const newIndex = index + direction
    if (newIndex < 0 || newIndex >= items.length) return

    const updatedItems = [...items];
    [updatedItems[index], updatedItems[newIndex]] = [updatedItems[newIndex], updatedItems[index]]

    setItems(updatedItems)
  }

  return { items, moveItem, setItems }
}
