import React from 'react'
import { ToggleInput, MultiStateSwitch, TextInput, NavigationFolder, Button, MultiSelectInput, SelectInput } from '@reply-pro/component-library'
import './SurveyDetailsSetup.scss'
import { SurveyDetailsTypes } from './hooks'
import PrivacyPolicySetup from './PrivacyPolicySetup'
import SurveyThemeSelector from '../Themes/SurveyThemeSelector'
import { isValidHexColor } from 'utils/common/isValidHexColor'
import { SurveyLocations } from 'types/location'
import { SURVEY } from 'pages/Settings/ManageSurveys/ManageSurveys'

export interface SurveyDetailField {
  title?: string | false;
  render?: (() => React.JSX.Element) | false
  toggle?: (() => React.JSX.Element) | false
}

interface SurveyDetailsProps {
  handleChange: (key: keyof SurveyDetailsTypes, value: string | boolean) => void;
  surveyDetails: SurveyDetailsTypes
  disabledSaveButton?: boolean
  handleSaveDetails: () => void
  isSavingDetails: boolean
  locations: SurveyLocations | undefined
  loadingLocations: boolean
  loadingAllLocations: boolean
  onAdd: (optionValue: string) => void
  onRemove: (optionValue: string) => void
  clearSelection: () => void
  isEditingDetails?: boolean
  handleSearch: (e: any) => void
  input: React.RefObject<unknown>
  values: string[]
  surveyType: string
  disabledLocationOptions?: string[]
}

const getLocationQuestionDisabledOptions = (isSavingDetails: boolean, disabledLocationOptions: string[]) => {
  if (isSavingDetails) {
    return ['disabled', 'question', 'code']
  }
  return disabledLocationOptions
}

const getSurveyFieldDetails: (props: SurveyDetailsProps) => SurveyDetailField[] = ({ surveyType, values, input, handleSearch, onAdd, onRemove, clearSelection, loadingLocations, loadingAllLocations, locations, surveyDetails, handleChange, disabledSaveButton, handleSaveDetails, isSavingDetails, isEditingDetails = false, disabledLocationOptions = [] }) => ([
  {
    title: `${surveyType} Name (Internal)`,
    render: () => (
      <TextInput
        className='detail-input'
        value={surveyDetails.surveyName}
        onChange={(e) => handleChange('surveyName', e.target.value)}
        disabled={isSavingDetails}
      />
    )
  },
  {
    title: !isEditingDetails && `${surveyType} Brand Color`,
    render: !isEditingDetails && (() => (
      <TextInput
        className='detail-input'
        value={surveyDetails.surveyBrandColor}
        error={surveyDetails.surveyBrandColor !== '' && !isValidHexColor(surveyDetails.surveyBrandColor || '')}
        onChange={(e) => handleChange('surveyBrandColor', e.target.value)}
        disabled={isSavingDetails}
        placeholder='#335F96'
      />
    ))
  },
  {
    title: 'Location Tracking',
    render: () => (
      <div className='container-with-description'>
        <MultiStateSwitch
          className='detail-input'
          labels={['Disabled', 'Question', 'Code']}
          values={['disabled', 'question', 'code']}
          value={surveyDetails.locationTracking}
          onOptionClicked={(value) => handleChange('locationTracking', value)}
          disabledOptions={getLocationQuestionDisabledOptions(isSavingDetails, disabledLocationOptions)}
        />
        <div className='description'>
          {surveyDetails.locationTracking === 'question' ? `Customers select their Location in a ${surveyType} Question` : surveyDetails.locationTracking === 'code' && "We'll generate a survey code for each Location"}
        </div>
      </div>
    )
  },
  {
    title: !(disabledLocationOptions[0] === 'disabled') && surveyDetails.locationTracking !== 'disabled' && 'Locations',
    render: surveyDetails.locationTracking !== 'disabled' && !(disabledLocationOptions[0] === 'disabled') && (() => (
      <MultiSelectInput
        className='detail-input'
        loading={loadingLocations}
        onAdd={onAdd}
        onRemove={onRemove}
        onClear={clearSelection}
        values={values}
        input={input}
        onInputChange={handleSearch}
        disabled={loadingLocations || surveyDetails.locationTracking === 'disabled' || surveyDetails.isAllLocations}
      >
        {!loadingLocations && locations?.locations?.edges?.map((location, index) => <option key={location.node.id + '-' + index} value={location.node.name}>{location.node.name}</option>)}
      </MultiSelectInput>
    )),
    toggle: !('disabled' in disabledLocationOptions) && (() => (
      <div className='locations-header'>
        <span className='all-locations'>All Locations</span>
        <ToggleInput
          checked={surveyDetails.isAllLocations}
          onChange={(e) => handleChange('isAllLocations', e.target.checked)}
          disabled={isSavingDetails || surveyDetails.locationTracking === 'disabled'}
        />
      </div>
    ))
  },
  {
    title: 'Required Entity',
    render: () => (
      <SelectInput
        className='detail-input'
        value={surveyDetails.requiredEntity}
        onChange={(e) => handleChange('requiredEntity', e.target.value)}
        disabled={isSavingDetails || surveyDetails.uniqueSubmission}
      >
        {['None', 'Interaction', 'Contact', 'User', 'RemoteID'].map((entity, index) => <option key={entity + '-' + index} value={entity}>{entity}</option>)}
      </SelectInput>
    )
  },
  surveyDetails.requiredEntity !== 'None'
    ? {
        title: 'Limit to one Response',
        toggle: () => (
          <ToggleInput
            checked={surveyDetails.uniqueSubmission}
            onChange={(e) => handleChange('uniqueSubmission', e.target.checked)}
            disabled={isSavingDetails}
          />
        )
      }
    : {},
  {
    title: 'Custom Privacy Policy Footer',
    toggle: () => (
      <ToggleInput
        checked={surveyDetails.customPrivacyPolicyFooter}
        onChange={(e) => handleChange('customPrivacyPolicyFooter', e.target.checked)}
        disabled={isSavingDetails}
      />
    ),
    render: surveyDetails.customPrivacyPolicyFooter && (() => <PrivacyPolicySetup surveyDetails={surveyDetails} handleChange={handleChange} />)
  },
  {
    title: surveyType === SURVEY && 'Save Partially Completed',
    toggle: () => (
      <ToggleInput
        checked={surveyDetails.savePartiallyCompleted}
        onChange={(e) => handleChange('savePartiallyCompleted', e.target.checked)}
        disabled={isSavingDetails}
      />
    )
  },
  {
    title: !isEditingDetails && surveyType === SURVEY && 'Ask Contact Details',
    toggle: !isEditingDetails && (() => (
      <ToggleInput
        checked={surveyDetails.askContactDetails}
        onChange={(e) => handleChange('askContactDetails', e.target.checked)}
        disabled={isSavingDetails}
      />
    ))
  },
  {
    render: !isEditingDetails && (() => (
      <NavigationFolder
        label={`${surveyType} ${surveyType === SURVEY ? 'Theme' : 'Style'}`}
        className='survey-styles-container'
        large
        rightAngled
        defaultExpanded
      >
        <SurveyThemeSelector selectedTheme={surveyDetails.selectedTheme || ''} handleChange={handleChange} surveyType={surveyType} />
      </NavigationFolder>
    ))
  },
  {
    render: () => (
      <div className='save-button-container'>
        <Button onClick={handleSaveDetails} disabled={loadingLocations || disabledSaveButton || isSavingDetails || loadingAllLocations}>Save Details</Button>
      </div>
    )
  }
]
)

export default getSurveyFieldDetails
