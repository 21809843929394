import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { storeReportingOrderData } from 'store/settingsSlice'

export interface OrderElement {
    id: string
    order: number
}

type Element = { id: string }

export const useOrderReports = <T extends Element>(updatedElements: T[], id: string) => {
  const { reportingReordering } = useSelector((state: RootState) => state.settings)
  const dispatch: AppDispatch = useDispatch()

  const updateOrder = (updatedElements: T[]) => {
    if (reportingReordering !== id) {
      return
    }
    const reorderedList = updatedElements.map((element, index) => ({
      id: element.id,
      order: index + 1
    }))
    dispatch(storeReportingOrderData(reorderedList))
  }

  useEffect(() => {
    updateOrder(updatedElements)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedElements])
}
