import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { REQUEST_PASSWORD_RESET, USER_NEW_PASSWORD } from 'requests/Users'
import { openNotificationSuccess } from 'store/settingsSlice'
import { copyToClipboard } from 'utils/common'
import { changeUserPasswordTrackingEvent, resetPasswordRequestTrackingEvent } from 'utils/dataTracking/usersTracking'

interface usePasswordManagerProps {
  setModalOpen: (bool: boolean) => void
}

export const usePasswordManager = ({ setModalOpen } : usePasswordManagerProps) => {
  const [requestPasswordReset, { data, loading }] = useMutation(REQUEST_PASSWORD_RESET)
  const dispatch = useDispatch()

  const handleRequestPasswordReset = (inputValue: { email?: string }) => {
    resetPasswordRequestTrackingEvent()
    requestPasswordReset({ variables: { input: inputValue } })
  }

  useEffect(() => {
    if (data?.userRequestPasswordReset?.success) {
      copyToClipboard(`${process.env.REACT_APP_WEB}/password-management/reset-password/${data?.userRequestPasswordReset?.created.id}`)
      dispatch(openNotificationSuccess('Password Reset Sent and reset url copied to clipboard!'))
      setModalOpen(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return { handleRequestPasswordReset, loading }
}

export const usePasswordSetup = ({ setModalOpen } : usePasswordManagerProps) => {
  const [requestPasswordReset, { data, loading }] = useMutation(USER_NEW_PASSWORD)
  const dispatch = useDispatch()

  const changeUserPassword = (inputValue: { email?: string, password: string }) => {
    changeUserPasswordTrackingEvent()
    requestPasswordReset({ variables: { input: inputValue } })
  }

  useEffect(() => {
    if (data?.userNewPasswordSetupByEmployee?.success) {
      dispatch(openNotificationSuccess('New Password Set!'))
      setModalOpen(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return { changeUserPassword, loading }
}
