import { LazyQueryExecFunction, OperationVariables } from '@apollo/client'
import getSurveyFieldDetails from './getSurveyFieldDetails'
import { useEditSurveyDetails, useGetLocations, useSaveEditedDetails } from './hooks'
import SurveyField from './SurveyField'
import { locationChoicesType } from 'store/surveysSlice/surveyTypes'

interface Props {
  surveyType: string
  getAllLocations: LazyQueryExecFunction<any, OperationVariables>
  loadingAllLocations: boolean
  allLocations?: locationChoicesType
  toggleIsEditingDetails: () => void
}

function SurveyEditDetails ({ surveyType, getAllLocations, loadingAllLocations, allLocations, toggleIsEditingDetails } : Props) {
  const { surveyDetails, handleChange, setSurveyDetails, disabledLocationOptions, editedFields, setEditedFields } = useEditSurveyDetails()
  const { input, onAdd, onRemove, clearSelection, locations, loadingLocations, handleSearch, values } = useGetLocations(surveyDetails, setSurveyDetails, getAllLocations, true)

  const { handleSaveDetails, isSavingDetails } = useSaveEditedDetails({ surveyDetails, editedFields, allLocations, toggleIsEditingDetails })
  const detailFields = getSurveyFieldDetails({
    surveyDetails,
    handleChange,
    disabledSaveButton: editedFields.length === 0,
    handleSaveDetails,
    isSavingDetails,
    locations,
    loadingLocations,
    loadingAllLocations,
    onAdd: (option) => {
      onAdd(option)
      setEditedFields([...editedFields, 'locations'])
    },
    isEditingDetails: true,
    onRemove: (option) => {
      onRemove(option)
      setEditedFields([...editedFields, 'locations'])
    },
    clearSelection,
    handleSearch,
    input,
    values,
    surveyType,
    disabledLocationOptions
  })

  return (
    <SurveyField detailFields={detailFields} />
  )
}

export default SurveyEditDetails
