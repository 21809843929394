import { SettingsPageStructure, NavItem, NavigationFolder, EmailPreview, Edit } from '@reply-pro/component-library'
import AccountDetailsHeader from '../AccountDetailsHeader'
import { useEffect, useMemo, useState } from 'react'
import EditEmailBrandingModal from './EditEmailBrandingModal'
import { parseWhiteLabelEmailData } from '../../../../utils/whiteLabel/parseWhiteLabelData'
import { camelCaseToCapitalizedWords } from 'utils/common/camelCaseToCapitalizedWords'
import { useLazyQuery } from '@apollo/client'
import { WHITE_LABEL } from 'requests/Accounts'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { WhiteLabel } from 'types/account'
import './EmailWhiteLabel.scss'

function EmailWhiteLabel () {
  const { accountId } = useSelector((store: RootState) => store.accounts)

  const [getWhiteLabelData, { data }] = useLazyQuery<WhiteLabel>(WHITE_LABEL, { variables: { account: accountId } })
  const whiteLabelData = useMemo(() => data ? parseWhiteLabelEmailData(data.whiteLabel) : null, [data])

  const [showEditEmailBrandingModal, setShowEditEmailBrandingModal] = useState(false)
  const [emailBrandingKey, setEmailBrandingKey] = useState<keyof ReturnType<typeof parseWhiteLabelEmailData> | null>(null)

  const handleShowEmailBrandingModal = (brandingKey: keyof ReturnType<typeof parseWhiteLabelEmailData>) => {
    setShowEditEmailBrandingModal(true)
    setEmailBrandingKey(brandingKey)
  }

  useEffect(() => {
    if (!data && accountId) {
      getWhiteLabelData()
    }
  }, [accountId, data, getWhiteLabelData])

  return (
    <>
      {showEditEmailBrandingModal && emailBrandingKey &&
        <EditEmailBrandingModal
          initialValue={whiteLabelData ? whiteLabelData[emailBrandingKey] : undefined}
          showEditEmailBrandingModal={showEditEmailBrandingModal}
          setShowEditEmailBrandingModal={setShowEditEmailBrandingModal}
          emailBrandingKey={emailBrandingKey}
          getWhiteLabelData={getWhiteLabelData}
        />}
      <SettingsPageStructure asideChildren={undefined} settingsHeader={<AccountDetailsHeader />}>
        <div className='email-page-container'>
          <NavigationFolder
            label='About this Account'
            large
            expanded
            labelRegular
            labelColor='gray'
            className='email-branding-nav-folder'
          >
            <div className='email-branding-options'>
              {whiteLabelData && (Object.keys(whiteLabelData) as Array<keyof typeof whiteLabelData>).map((key) => (
                <NavItem key={key} className='email-branding-option' onClick={() => handleShowEmailBrandingModal(key)}>
                  <div className='email-branding-container'>
                    <div className='email-branding-details'>
                      <div className='label'>{camelCaseToCapitalizedWords(key)}</div>
                      <div className='value'>{whiteLabelData[key] || 'Not provided'}</div>
                    </div>
                    <div className='email-branding-actions'>
                      <Edit className='edit' />
                    </div>
                  </div>
                </NavItem>
              ))}
            </div>
          </NavigationFolder>
          <EmailPreview
            className='email-branding-preview'
            topLogo={whiteLabelData?.topLogo}
            bottomLogo={whiteLabelData?.bottomLogo}
            buttonColor={whiteLabelData?.buttonColor}
            linkColor={whiteLabelData?.linkColor}
            emailSignature={whiteLabelData?.emailSignature}
            emailFooter={whiteLabelData?.footer}
          />
        </div>
      </SettingsPageStructure>
    </>
  )
}

export default EmailWhiteLabel
