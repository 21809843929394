import { Outlet, Routes, Route } from 'react-router-dom'

import AppHeader from './AppHeader'

import AppNav from './AppNav'
import SettingsNav from 'pages/Settings/SettingsNav'

import Notifications from 'components/Notifications/Notifications'
import { refresh } from 'store/metadataSlice/index'
import { useDispatchAction } from 'utils/common/useDispatchAction'
import SettingsHeader from 'pages/Settings/SettingsHeader'
import './App.scss'

function App () {
  useDispatchAction(refresh)
  return (
    <div className='app-wrapper'>
      <Routes>
        <Route element={<AppHeader />}>
          <Route path='/settings/*' element={<SettingsHeader />} />
          <Route path='*' element={<div />} />
        </Route>
      </Routes>

      <main>
        <Routes>
          <Route element={<AppNav />}>
            <Route path='/settings/*' element={<SettingsNav />} />
            <Route path='*' element={<SettingsNav />} />
          </Route>
        </Routes>

        <div className='context-screen'>
          <Outlet />
        </div>
      </main>
      <Notifications />
    </div>
  )
}

export default App
