import { LazyQueryExecFunction, OperationVariables } from '@apollo/client'
import getSurveyFieldDetails from './getSurveyFieldDetails'
import { useGetLocations, useSaveDetails, useSurveyDetailsSetup } from './hooks'
import SurveyField from './SurveyField'

interface Props {
  surveyType: string
  getAllLocations: LazyQueryExecFunction<any, OperationVariables>
  loadingAllLocations: boolean
}

function SurveyDetailsSetup ({ surveyType, getAllLocations, loadingAllLocations } : Props) {
  const { surveyDetails, handleChange, disabledSaveButton, setSurveyDetails } = useSurveyDetailsSetup()
  const { input, onAdd, onRemove, clearSelection, locations, loadingLocations, handleSearch, values } = useGetLocations(surveyDetails, setSurveyDetails, getAllLocations)
  const { handleSaveDetails, isSavingDetails } = useSaveDetails({ surveyDetails, surveyType })
  const detailFields = getSurveyFieldDetails({
    surveyDetails,
    handleChange,
    disabledSaveButton,
    handleSaveDetails,
    isSavingDetails,
    locations,
    loadingLocations,
    loadingAllLocations,
    onAdd,
    onRemove,
    clearSelection,
    handleSearch,
    input,
    values,
    surveyType
  })

  return (
    <SurveyField detailFields={detailFields} />
  )
}

export default SurveyDetailsSetup
