import { Check, InfoIcon, Notification, Warning } from '@reply-pro/component-library'
import { useNotifications } from './useNotifications'
import './Notifications.scss'

function Notifications () {
  const { successMessage, errorMessage, dismissNotifications, loadingMessage, warningMessage } = useNotifications()

  const message = successMessage || errorMessage || warningMessage || loadingMessage || ''
  const type = successMessage ? 'success' : errorMessage ? 'error' : warningMessage ? 'default' : 'loading'
  const icon = successMessage ? Check : errorMessage ? Warning : warningMessage ? InfoIcon : undefined
  const buttonLabel = successMessage || errorMessage ? 'Dismiss' : warningMessage ? 'Understood' : undefined
  const buttonBackgroundColor = successMessage || errorMessage ? 'dark' : warningMessage ? 'red2' : undefined
  const buttonColor = 'white'
  const borderWidth = errorMessage || warningMessage ? '1px' : undefined
  const borderColor = errorMessage ? 'red' : warningMessage ? 'lighterGray' : undefined
  const iconBackgroundColor = warningMessage ? 'red2' : undefined
  const autoDismissMs = successMessage || errorMessage ? 4000 : undefined

  return (
    <Notification
      className='notification'
      contents={message}
      type={type}
      icon={icon}
      backgroundColor='white'
      color='dark'
      borderWidth={borderWidth}
      borderColor={borderColor}
      iconBackgroundColor={iconBackgroundColor}
      button={
        buttonLabel
          ? {
              label: buttonLabel,
              onClick: dismissNotifications,
              backgroundColor: buttonBackgroundColor,
              color: buttonColor
            }
          : undefined
      }
      dismiss={dismissNotifications}
      autoDismissMs={autoDismissMs}
      dismissBarColor='dark'
      show={!!message}
    />
  )
}

export default Notifications
