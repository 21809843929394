import { useSelector } from 'react-redux'
import { RootState } from 'store'
import Section from './Section'
import { useReorder } from 'components/SortableList/hooks'
import { SortableList } from 'components/SortableList/SortableList'
import './SectionsList.scss'
import { useOrderReports } from './hooks'

export default function SectionsList () {
  const { reportCollectionDetail } = useSelector((state: RootState) => state.settings)

  const orderedSections = reportCollectionDetail?.reportSections ? [...reportCollectionDetail.reportSections].sort((a, b) => a.order - b.order) : []

  const { items: sections, moveItem } = useReorder(orderedSections || [])

  useOrderReports(sections, 'section')

  return (
    <SortableList
      items={sections}
      moveItem={moveItem}
      className='sections-list'
      renderItem={(section, index, moveItem) => (
        <Section
          key={section.id}
          sectionName={section.name}
          reportPages={section.reportPages}
          sectionId={section.id?.toString() || ''}
          moveUp={() => moveItem(index, -1)}
          moveDown={() => moveItem(index, 1)}
          disableUp={index === 0}
          disableDown={index === sections.length - 1}
        />
      )}
    />
  )
}
