import { reportPage } from 'store/settingsSlice/settingsTypes'
import Report from './Report/Report'
import { useReorder } from 'components/SortableList/hooks'
import { SortableList } from 'components/SortableList/SortableList'
import './reportsList.scss'
import { useOrderReports } from '../../hooks'

interface ReportsListProps {
  reportPages: reportPage[]
  sectionId: string
  reportingReordering?: string
}

export default function ReportsList ({ reportPages, sectionId, reportingReordering }: ReportsListProps) {
  const isReorderingReportsInSection = reportingReordering === sectionId

  const { items: sortedReports, moveItem } = useReorder(reportPages)
  useOrderReports(sortedReports, sectionId)

  return (
    <>
      <SortableList
        items={sortedReports}
        moveItem={moveItem}
        className='report-list'
        renderItem={(page: reportPage, index: number) => (
          <Report
            users={page.users.edges}
            userGroups={page.userGroups.edges}
            reportName={page.name}
            isForAnyUser={page.isForAnyUser}
            key={page.id}
            link={page.link}
            remoteId={page.remoteId}
            embedType={page.embedType}
            reportId={page.id}
            sectionId={sectionId}
            authorized={page.authorized}
            parameters={JSON.parse(page.parameters)}
            isReorderingReportsInSection={isReorderingReportsInSection}
            moveUp={() => moveItem(index, -1)}
            moveDown={() => moveItem(index, 1)}
            disableUp={index === 0}
            disableDown={index === sortedReports.length - 1}
          />
        )}
      />
    </>
  )
}
