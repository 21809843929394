import { /* Edit, */ NavigationFolder, PlusAdd, ReorderIcon, RoundCheckIcon, SortingArrows } from '@reply-pro/component-library'
import { reportPage } from 'store/settingsSlice/settingsTypes'
import ReportsList from './ReportsList/ReportsList'
import ElementDelete from 'components/ElementDelete'
import { removeReportSection } from 'store/settingsSlice/settingsReducers'
import CreateReportModal from './CreateReportModal/CreateReportModal'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import './Section.scss'
import { useReorderReports } from 'pages/Settings/ManageReports/hooks'

interface SectionActionsProps {
  sectionId: string
  sectionName: string
  reportingReordering?: string
}

function SectionActions ({ sectionId, sectionName, reportingReordering }: SectionActionsProps) {
  const [isShowCreateReportModal, setIsShowCreateReportModal] = useState(false)
  const { startReordering, saveReordering } = useReorderReports(sectionId)

  const toggleIsShowCreateReportModal = () => setIsShowCreateReportModal(prev => !prev)

  return (
    <div className='section-options' onClick={(e) => e.stopPropagation()}>
      <div>
        {reportingReordering === sectionId
          ? <RoundCheckIcon onClick={saveReordering} className='reorder-report-page-icon save' />
          : <ReorderIcon onClick={startReordering} className='reorder-report-page-icon' />}
      </div>
      <div>
        <PlusAdd onClick={toggleIsShowCreateReportModal} className='add-report-page-icon' />
        <CreateReportModal
          reportSectionId={sectionId}
          isShowCreateReportModal={isShowCreateReportModal}
          toggleIsShowCreateReportModal={toggleIsShowCreateReportModal}
        />
      </div>
      {/* <div>
        <Edit />
      </div>
      //not in the scope of this sprint. We can plan for it later */}
      <div>
        <ElementDelete
          deleteDispatcher={removeReportSection}
          id={sectionId}
          name={sectionName}
          type='section and all the pages on it'
        />
      </div>
    </div>
  )
}
interface SectionProps {
  sectionName: string;
  reportPages: reportPage[];
  sectionId: string;
  moveUp: () => void;
  moveDown: () => void;
  disableUp: boolean;
  disableDown: boolean;
}

export default function Section ({
  sectionName, reportPages, sectionId,
  moveUp, moveDown, disableUp, disableDown
}: SectionProps) {
  const { reportingReordering } = useSelector((state: RootState) => state.settings)

  return (
    <NavigationFolder
      labelColor='gray'
      className='section-listing'
      label={sectionName}
      large
      rightAngled
      expanded={reportingReordering === sectionId || (reportingReordering === 'section' ? false : undefined)}
      subElements={
        <>
          {reportingReordering === 'section'
            ? (
              <SortingArrows
                onUpClick={moveUp}
                onDownClick={moveDown}
                disabledUp={disableUp}
                disabledDown={disableDown}
              />
              )
            : (
              <SectionActions
                sectionName={sectionName}
                sectionId={sectionId}
                reportingReordering={reportingReordering}
              />
              )}
        </>
      }
    >
      <ReportsList reportPages={reportPages} sectionId={sectionId} reportingReordering={reportingReordering} />
    </NavigationFolder>
  )
}
