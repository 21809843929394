import { ActivitiesIcon, Button, Inbox, Reports, Settings, ToggleInput, ToolTip, UserGroup as UserGroupIcon } from '@reply-pro/component-library'
import { Feature } from 'types/account'
import React, { useRef, useState } from 'react'
import { concatStringsWithType, getLowerCaseStringWithType, getObjectEntriesWithTypes, getObjectKeysWithTypes } from 'types/common'
import classNames from 'classnames'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import './FeaturesSetter.scss'

type StringFeatures = Feature extends `${infer Prefix}/*` ? Prefix : Feature
type FeaturesObject = Record<Capitalize<StringFeatures>, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>

const FEATURES: FeaturesObject = { Inbox, Reporting: Reports, Activities: ActivitiesIcon, Settings }

interface BaseProps {
  features?: Feature[]
  setFeaturesAction?: (features: Feature[]) => Promise<any>
}

interface AccountProps extends BaseProps {
  entity: 'Account'
  userGroupWarning?: boolean
}

interface UserGroupProps extends BaseProps {
  entity: 'User Group'
  accountFeatures?: Feature[]
  reportingWarning?: boolean
}

const FeaturesSetter = (props: AccountProps | UserGroupProps) => {
  const { entity, features, setFeaturesAction } = props

  const [hasFeatures, setHasFeatures] = useState(entity === 'User Group' ? features && features.length > 0 : true)
  const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>(features && features.length > 0 ? features : PARSED_FEATURES)
  const [clickedFeature, setClickedFeature] = useState<Feature | undefined>(undefined)

  const handleHasFeatures = (checked: boolean) => {
    if (entity === 'Account' || !props.accountFeatures) return

    setHasFeatures(checked)

    const callback = () => {
      setSelectedFeatures(selectedFeatures)
      setHasFeatures(hasFeatures)
    }

    if (checked) {
      const accountFeatures = props.accountFeatures.length === 0 ? PARSED_FEATURES : props.accountFeatures

      setSelectedFeatures(accountFeatures)
      setFeaturesAction?.(accountFeatures).catch(callback)
    } else {
      setSelectedFeatures([])
      setFeaturesAction?.([]).catch(callback)
    }
  }

  const handleSetSelectedFeatures = (clickedFeature: Feature) => {
    let newFeatures = []

    if (selectedFeatures && selectedFeatures.includes(clickedFeature)) {
      newFeatures = selectedFeatures.filter(feature => feature !== clickedFeature)
    } else {
      newFeatures = [...(selectedFeatures ?? []), clickedFeature]
    }

    const callback = () => {
      setSelectedFeatures(selectedFeatures)
    }

    setSelectedFeatures(newFeatures)
    setFeaturesAction?.(newFeatures.length === Object.keys(FEATURES).length && entity === 'Account' ? [] : newFeatures).catch(callback)
    setClickedFeature(undefined)
  }

  const buttonRef = useRef<HTMLButtonElement>(null)
  const userGroupIconRef = useRef<HTMLDivElement>(null)
  const toggleInputRef = useRef<HTMLDivElement>(null)

  return (
    <>
      {clickedFeature &&
        <ConfirmationModal
          showModal={Boolean(clickedFeature)}
          warningFirstLine={`You are about to enable or disable a Feature for this ${entity}.`}
          onConfirm={() => handleSetSelectedFeatures(clickedFeature)}
          confirmText='TOGGLE'
          buttonText='Confirm'
          modalTitle='enable/disable feature'
          toggleModalOpen={() => setClickedFeature(undefined)}
        />}

      <div className='features-setter'>
        {entity === 'Account' && props.userGroupWarning &&
          <>
            <ToolTip anchor={userGroupIconRef}>This Account contains User Groups with assigned Features.</ToolTip>
            <div ref={userGroupIconRef} className='user-group-warning'><UserGroupIcon /></div>
          </>}

        {entity === 'User Group' &&
          <>
            <ToolTip anchor={toggleInputRef}>{`${!hasFeatures ? 'Enable' : 'Disable'} specific Features for this User Group.`}</ToolTip>
            <div ref={toggleInputRef} className='features-toggle-input-setter'><ToggleInput checked={hasFeatures} onChange={(e) => handleHasFeatures(e.target.checked)} /></div>
          </>}

        <div className={classNames('features-setter-buttons-container', { 'not-features': entity === 'User Group' && !hasFeatures })}>
          {getObjectEntriesWithTypes(FEATURES).map(([feature, Icon]) => {
            const parsedFeature = concatStringsWithType(getLowerCaseStringWithType(feature), ('/*'))

            return (
              <div key={`feature-${feature.toLowerCase()}`}>
                {entity === 'User Group' && props.reportingWarning && feature === 'Reporting' &&
                  <ToolTip anchor={buttonRef}>This User Group is linked to specific Report Pages, but users won't have access while the Feature is disabled.</ToolTip>}
                <Button
                  ref={feature === 'Reporting' ? buttonRef : null}
                  onClick={() => !(selectedFeatures.length === 1 && selectedFeatures.includes(parsedFeature)) && setClickedFeature(parsedFeature)}
                  stroked
                  small
                  disabled={entity === 'User Group' && !hasFeatures}
                  className={classNames(
                    'feature-button',
                    {
                      'not-allowed': !selectedFeatures.includes(parsedFeature) && hasFeatures,
                      disabled: selectedFeatures.length === 1 && selectedFeatures.includes(parsedFeature), // Escaping HTML Native behavior
                      warning: entity === 'User Group' && props.reportingWarning && feature === 'Reporting'
                    }
                  )}
                  hidableText
                  Icon={Icon}
                >
                  {feature}
                </Button>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

const PARSED_FEATURES = getObjectKeysWithTypes(FEATURES).map(feature => concatStringsWithType(getLowerCaseStringWithType(feature), '/*'))

export default FeaturesSetter
