import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { syncSurveyQuestions } from 'store/surveysSlice/surveysDispatchers'

export const useSurveySetupButtons = (browserId?: string, id?: string) => {
  const { localData, isSavingChanges, pendingSyncChanges } = useSelector((state: RootState) => state.surveys.surveyQuestions)
  const dispatch: AppDispatch = useDispatch()
  const saveSurveyQuestions = async () => {
    if (localData) {
      await dispatch(syncSurveyQuestions(localData))
    }
  }

  return { saveSurveyQuestions, localData, isSavingChanges, pendingSyncChanges }
}

export const useLastSaveTime = () => {
  const [timeSinceLastSync, setTimeSinceLastSync] = useState<string>('')

  const { lastSynced, isSavingChanges } = useSelector((state: RootState) => state.surveys.surveyQuestions)

  useEffect(() => {
    if (isSavingChanges) {
      setTimeSinceLastSync('Saving...')
      return
    }
    if (lastSynced) {
      const updateSaveTime = () => {
        const lastSyncTime = new Date(lastSynced)
        const currentTime = new Date()
        const timeDifference: number = Math.floor((currentTime.getTime() - lastSyncTime.getTime()) / (1000 * 60))

        if (timeDifference < 1) {
          setTimeSinceLastSync('Saved < 1 min ago')
          return
        }
        const pluralSuffix = timeDifference === 1 ? '' : 's'
        setTimeSinceLastSync(`Saved ${timeDifference} min${pluralSuffix} ago`)
      }

      updateSaveTime()

      const intervalId = setInterval(updateSaveTime, 60000)

      return () => clearInterval(intervalId)
    }

    setTimeSinceLastSync('')
  }, [lastSynced, isSavingChanges])

  return { timeSinceLastSync }
}
