import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react'
import 'survey-core/survey.i18n.js'
import 'survey-creator-core/survey-creator-core.i18n.js'
import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { setLicenseKey, Serializer } from 'survey-core'
import { useSetupInitialQuestions, useSurveyCreator } from './hooks'
import { LoadingSpinner } from '@reply-pro/component-library'
import locationsState from 'store/accountsSlice/locations/types.locations'
import './SurveyCreator.scss'
import { DefaultFonts } from 'survey-creator-core'
import { generateUid } from 'utils/common'

Serializer.findProperty('question', 'name').readOnly = true

interface Props {
  surveyType: string
  selectedLocations?: locationsState['locationsSelected']
  isEditing: boolean
  isEditingDetails: boolean
}

let questionNumber = 1
const fontLink = document.createElement('link')
fontLink.rel = 'stylesheet'
fontLink.href = 'https://fonts.googleapis.com/css2?family=Google+Sans:wght@400&family=Google+Sans+Display:wght@400&family=Google+Sans+Text:wght@400&family=Product+Sans:wght@400&display=swap'
document.head.appendChild(fontLink)

function SurveyCreatorRenderComponent ({ surveyType, selectedLocations, isEditing, isEditingDetails }: Props) {
  const { locationTracking, askContactDetails } = useSelector((state: RootState) => state.surveys.surveySetup.data)
  const { disabled } = useSelector((state: RootState) => state.surveys.surveySetup)

  const themeJSON = useSelector((state: RootState) => state.surveys.surveySetup.data?.theme)
  const json = useSelector((state: RootState) => state.surveys.surveySetup.data?.json)
  const { saveLocalChanges } = useSurveyCreator()

  if (process.env.REACT_APP_SURVEY_JS_LICENSE) {
    setLicenseKey(process.env.REACT_APP_SURVEY_JS_LICENSE)
  }

  const themeObject = JSON.parse(themeJSON)
  const options = {
    showLogicTab: true,
    showThemeTab: true,
    showObjectTitles: true,
    showTitlesInExpressions: true
  }
  const creator = new SurveyCreator(options)
  if (!DefaultFonts.includes('Google Sans Display, sans-serif')) {
    DefaultFonts.push('Google Sans Display, sans-serif')
  }
  creator.onModified.add(() => {
    saveLocalChanges(creator.JSON)
  })

  creator.onQuestionAdded.add((_, options) => {
    if (options.reason === 'ELEMENT_CONVERTED') {
      return
    }
    const question = options.question
    question.name = generateUid()
    question.title = 'Question' + questionNumber
    questionNumber++
  })

  // Apply theme
  if (themeObject.theme_json) {
    creator.theme = themeObject.theme_json
  }

  // Initialize questions if exist
  if (json) {
    creator.JSON = JSON.parse(json)
  }

  Serializer.findProperty('file', 'storeDataAsText').defaultValue = false
  Serializer.findProperty('file', 'storeDataAsText').visible = false

  creator.onUploadFile.add(function (_, options) {
    const file = options.files[0]

    const formData = new FormData()
    formData.append('mimetype', file.type)
    formData.append('name', file.name)
    formData.append('file', file)

    fetch(`${process.env.REACT_APP_SERVICES_BASE}/files/un-auth`, {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        const fileUrl = data.result.resource.url
        options.callback('success', fileUrl)
      })
  })

  // Here we insert the location question if needed
  useSetupInitialQuestions({ locationTracking, askContactDetails, creator, selectedLocations, surveyType, isEditing })

  return (
    <>
      {(disabled || isEditingDetails) &&
        <div className='saving-changes-overlay'>
          {isEditingDetails ? <>Save {surveyType} Details to use the {surveyType} Editor</> : <><LoadingSpinner size='small' /> Saving Changes...</>}
        </div>}
      <SurveyCreatorComponent creator={creator} />
    </>
  )
}

export default SurveyCreatorRenderComponent
