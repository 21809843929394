import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { openNotificationLoading, openNotificationSuccess, setReportReordering } from 'store/settingsSlice'
import { reorderEntity } from 'store/settingsSlice/settingsReducers'

export const useReorderReports = (type: string) => {
  const { sortedReportingData } = useSelector((state: RootState) => state.settings)
  const dispatch: AppDispatch = useDispatch()
  const startReordering = () => {
    dispatch(setReportReordering(type))
  }
  const saveReordering = async () => {
    if (sortedReportingData && sortedReportingData.length > 0) {
      dispatch(openNotificationLoading('Reordering...'))
      await dispatch(reorderEntity({ entity: type === 'section' ? 'Section' : 'ReportPage', order: sortedReportingData }))
      dispatch(openNotificationSuccess('Reordering successful'))
    } else {
      dispatch(setReportReordering(undefined))
    }
  }

  return { startReordering, saveReordering }
}
