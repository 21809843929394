import { BinaryInput, Button, CheckInput, TextInput } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import UserEditor from 'components/UserEditor'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import AddCustomFilters from 'components/AddCustomFilters'
import { getMetabaseBaseUrl, getMetabaseDashboardIdFromUrl } from 'utils/common/metabaseUrlHandlers'
import Tooltip from 'components/Tooltip'
import { useAddUserToReportPage, useAddUserGroupToReportPage, useRemoveUserToReportPage, useRemoveUserGroupToReportPage } from './hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import './reportEditor.scss'
import { motion } from 'framer-motion'

interface ReportEditorProps {
  title: string
  isShowModal: boolean
  toggleModalOpen: () => void
  reportName: string
  isBasicEmbedType: boolean
  reportLink?: string
  onSubmit: () => void
  setReportName: (name: string) => void
  setIsBasicEmbedType: (isBasicEmbedType: boolean) => void
  setReportLink: (link: string) => void
  setReportDashboardId: (dashboardId: string) => void
  isForAllUsers: boolean
  setIsForAllUsers: (setter: (prev: boolean) => boolean) => void
  disabled?: boolean
  submitText?: string
  addedFilters?: Record<string, string[] | null>
  setAddedFilters: Dispatch<SetStateAction<Record<string, string[] | null> | undefined>>
  isAuthorized: boolean
  setIsAuthorized: Dispatch<SetStateAction<boolean>>
  disabledReason?: string
}

export default function ReportEditor ({
  title,
  isShowModal,
  toggleModalOpen,
  reportName,
  isBasicEmbedType,
  reportLink,
  onSubmit,
  setReportName,
  setIsBasicEmbedType,
  setReportLink,
  setReportDashboardId,
  isForAllUsers,
  setIsForAllUsers,
  disabled,
  submitText = 'Save',
  addedFilters,
  setAddedFilters,
  isAuthorized,
  setIsAuthorized,
  disabledReason
}: ReportEditorProps) {
  const selectedUsers = useSelector((state: RootState) => state.settings.reportPageUsers)
  const selectedUserGroups = useSelector((state: RootState) => state.settings.reportPageUserGroups)

  const [linkInput, setLinkInput] = useState(reportLink)
  const [showCustomFiltersModal, setShowCustomFiltersModal] = useState(false)

  const addUser = useAddUserToReportPage()
  const removeUser = useRemoveUserToReportPage()

  const addUserGroup = useAddUserGroupToReportPage()
  const removeUserGroup = useRemoveUserGroupToReportPage()

  const submitRef = useRef<HTMLButtonElement>(null)

  const handleAuthorizedInput = (value: string) => {
    setLinkInput(value)
    setReportLink(getMetabaseBaseUrl(value))
    setReportDashboardId(getMetabaseDashboardIdFromUrl(value))
  }

  const handleBasicInput = (value: string) => {
    setLinkInput(value)
    setReportLink(value)
  }

  const handleBinaryInput = (isLeftOn: boolean) => {
    setIsBasicEmbedType(isLeftOn)

    if (isLeftOn) {
      setReportLink(linkInput ?? '')
    }
    if (!isLeftOn) {
      setReportLink(reportLink ? getMetabaseBaseUrl(reportLink) : '')
    }
  }

  return (
    <>
      {showCustomFiltersModal && linkInput &&
        <AddCustomFilters
          showCustomFiltersModal={showCustomFiltersModal}
          setShowCustomFiltersModal={setShowCustomFiltersModal}
          baseUrl={getMetabaseBaseUrl(linkInput)}
          dashboardId={getMetabaseDashboardIdFromUrl(linkInput)}
          setAddedFilters={setAddedFilters}
          addedFilters={addedFilters}
        />}
      <Modal
        title={title}
        showModal={isShowModal}
        toggleModalOpen={toggleModalOpen}
        actions={
          <>
            {disabledReason && disabled &&
              <Tooltip anchor={submitRef}>
                {disabledReason}
              </Tooltip>}
            <Button ref={submitRef} disabled={disabled} onClick={onSubmit}>
              {submitText}
            </Button>
          </>
        }
      >
        <motion.div className='create-edit-report-modal'>
          <div className='report-options'>
            <h3>Report Name</h3>
            <p>Report Names are used to identify your reports.</p>
            <TextInput placeholder='Name of the report' value={reportName} onChange={(e) => setReportName(e.target.value)} />
          </div>
          <div className='report-options'>
            <h3>Dashboard</h3>
            <p>Select the embed type of the dashboard.</p>
            <BinaryInput leftOption='BASIC' rightOption='CUSTOM' isLeftOn={isBasicEmbedType} onLeftOn={handleBinaryInput} />
            <p>
              Copy the dashboard {isBasicEmbedType ? 'public link' : 'URL'} you’d like to embed from Metabase and paste it below.{' '}
              {!isBasicEmbedType && (
                <a href='https://learn.zoho.com/portal/go-mgm/knowledge/manual/customer-success/article/auhtorized-reports' rel='noreferrer' target='_blank'>
                  Check step-by-step guide.
                </a>
              )}
            </p>
            <TextInput
              placeholder={isBasicEmbedType ? 'Metabase dashboard public link' : 'Metabase dashboard URL'}
              value={linkInput}
              onChange={(e) => (isBasicEmbedType ? handleBasicInput(e.target.value) : handleAuthorizedInput(e.target.value))}
              type='url'
            />
            {!isBasicEmbedType &&
              <div className='report-options custom-filters'>
                <div className='custom-filters-options-check-input'>
                  <CheckInput
                    label='Authorized'
                    checked={isAuthorized}
                    onChange={(e) => setIsAuthorized(e.target.checked)}
                  />
                  <p>Authorized will check for user permissions to display the charts data.</p>
                </div>
                <Button
                  stroked
                  onClick={() => setShowCustomFiltersModal(true)}
                  disabled={!linkInput}
                >
                  {`Manage Filters ${addedFilters ? `(${Object.values(addedFilters).flat().filter(addedFilter => addedFilter).length})` : ''}`}
                </Button>
              </div>}
          </div>
          <div className='report-options'>
            <UserEditor
              selectedUsers={Object.entries(selectedUsers).map(([id, user]) => ({ id, ...user }))}
              isForAllUsers={isForAllUsers}
              setIsForAllUsers={setIsForAllUsers}
              addUserAction={addUser}
              removeUserAction={removeUser}
              selectedUserGroups={Object.entries(selectedUserGroups).map(([id, userGroup]) => ({ id, ...userGroup }))}
              addUserGroupAction={addUserGroup}
              removeUserGroupAction={removeUserGroup}
            />
          </div>
        </motion.div>
      </Modal>
    </>
  )
}
