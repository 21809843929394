import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { retrieveSurvey } from 'store/surveysSlice/surveysDispatchers'

export const useSurveySetupId = (id?: string) => {
  const { data } = useSelector((state: RootState) => state.surveys.surveySetup)

  const dispatch: AppDispatch = useDispatch()
  useEffect(() => {
    if (id && !data) {
      dispatch(retrieveSurvey(id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, !data])
}
