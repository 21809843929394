import { useSelector } from 'react-redux'
import { LoadingSpinner, NavItem } from '@reply-pro/component-library'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { RootState } from 'store'
import { retrieveReportCollectionsList } from 'store/settingsSlice/settingsReducers'
import { useDispatchAction } from 'utils/common/useDispatchAction'
import './manageReportsCollections.scss'
import CollectionOptions from './CollectionOptions'

function ManageReportsCollections () {
  const { data } = useSelector((state: RootState) => state.settings.reportCollections)
  const { pathname } = useLocation()

  useDispatchAction(retrieveReportCollectionsList)
  return (
    <div className='report-collections-list'>
      {!data
        ? <div className='central-loading-spinner'><LoadingSpinner /></div>
        : data?.reportCollections?.edges.length > 0
          ? data?.reportCollections?.edges.map(({ node: collection }) =>
            <div key={collection.id} className='collection-nav-item'>
              <NavItem
                component={NavLink}
                to={`/settings/manage-reports/collection/${collection.id}`}
                className={classNames({ active: pathname === `/settings/manage-reports/collection/${collection.id}` })}
              >
                <div className='collection-name'>
                  {collection.name.toUpperCase()}
                </div>
              </NavItem>
              <CollectionOptions collectionId={collection.id} />
            </div>
          )
          : <span className='no-items-found'>No collections for current account</span>}
    </div>
  )
}

export default ManageReportsCollections
