import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { setSurveyLocalQuestionsData } from 'store/surveysSlice'
import { getAskContactDetailsQuestions, getLocationLockedQuestion } from './defaultQuestions'
import { FORM } from 'pages/Settings/ManageForms/ManageForms'
import { SurveyCreator } from 'survey-creator-react'
import locationsState from 'store/accountsSlice/locations/types.locations'
import { syncSurveyQuestions } from 'store/surveysSlice/surveysDispatchers'

export const useSurveyCreator = () => {
  // const { locationTracking } = useSelector((state: RootState) => state.surveys.surveySetup.data)
  // This will help to include the initial Location question if required
  const dispatch: AppDispatch = useDispatch()
  const saveLocalChanges = (changes: object) => {
    dispatch(setSurveyLocalQuestionsData(changes))
  }

  return { saveLocalChanges }
}

interface SetupInitialQuestionsProps {
  creator: SurveyCreator
  locationTracking: string
  askContactDetails: boolean
  selectedLocations?: locationsState['locationsSelected']
  surveyType: string
  isEditing: boolean
}

export const useSetupInitialQuestions = ({ creator, locationTracking, askContactDetails, selectedLocations, surveyType, isEditing }: SetupInitialQuestionsProps) => {
  const dispatch: AppDispatch = useDispatch()
  // Initialize an empty array to store elements
  useEffect(() => {
    const surveyElements = []

    // insert the location question if needed
    if (!isEditing && (locationTracking || askContactDetails)) {
      if (locationTracking === 'question') {
        surveyElements.push(getLocationLockedQuestion(selectedLocations!))

        // disallow deletion of location question
        creator.onElementAllowOperations.add(function (sender, options) {
          if ((options.obj as any).name === 'location-locked-question') {
            options.allowDelete = false
          }
        })
      }
      // Add contact details questions if needed
      if (askContactDetails || surveyType === FORM) {
        surveyElements.push(
          ...getAskContactDetailsQuestions()
        )
      }

      creator.JSON = {
        elements: surveyElements
      }
      dispatch(syncSurveyQuestions(creator.JSON))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
