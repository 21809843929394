import { ShowTags } from '@reply-pro/component-library'
import { useTagGroupsFilteredByEntities } from 'utils/hooks/tagGroups'
import { ENTITIES } from '../AddPostSurveyActionsModal/constants'

const { INTERACTION } = ENTITIES

function PostActionsTagsPreview ({ attributes }: { attributes: string }) {
  const parsedAttributes = JSON.parse(attributes)

  const { filteredTagGroup } = useTagGroupsFilteredByEntities([INTERACTION])

  const tagFieldLookup = filteredTagGroup
    ?.flatMap(group => group.node.tagFields)
    ?.reduce((acc: Record<string, string>, tagField: { uuid: string; label: string }) => {
      acc[tagField.uuid] = tagField.label
      return acc
    }, {})

  const tags = parsedAttributes.tags.map((tag: { value: string; tag_field: string }) => ({
    value: tag.value,
    tagField: tagFieldLookup?.[tag.tag_field] || 'Unknown Tag Field'
  }))

  return (
    <div className='post-action-tags-container'>
      <ShowTags tags={tags} />
    </div>
  )
}

export default PostActionsTagsPreview
