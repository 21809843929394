import { SurveyDetailField } from './getSurveyFieldDetails'
import './SurveyDetailsSetup.scss'

export default function SurveyField ({ detailFields }: { detailFields: SurveyDetailField[] }) {
  return (
    <div className='details-setup-container'>
      {detailFields.map(
        ({ title, render, toggle }, index) =>
          (title || render) && (
            <div
              key={index}
              className='details-input-container'
            >
              {title && (
                <div className='detail-title-container'>
                  {title && <span className='detail-title'>{title}</span>}
                  {toggle && toggle()}
                </div>
              )}
              {render && <div className='detail-content'>{render()}</div>}
            </div>
          )
      )}
    </div>
  )
}
